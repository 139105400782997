import React from "react";
import { graphql } from "gatsby";
import BackLink from "../components/Link/BackLink";
import getGenericPage, { GenericPage } from "./generic/genericPage";

const PageContent = () =>
	<BackLink title="בחזרה למילון" path="/glossary" />;

export const GlossaryItemTemplate = ({ post }) =>
	<GenericPage page={post} siteTitle="PREVIEW" >
		{PageContent}
	</GenericPage>;

export const glossaryPageQuery = graphql`
  query GloassaryPageQuery($id: String!) {
    markdownRemark(id: {eq: $id }) {
      ...GenericPageFragment
    }
  highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 3) {
    edges {
      ...EventHighlight
    }
  }
}`;

export default getGenericPage(PageContent,
	{ childrenInsideMainSection: true, showSocialShare: true });
