import React, { useContext } from "react";
import styled from "styled-components";
import { FormNext } from "grommet-icons";
import PreviewContext from "../Layout/PreviewContext";
import NavLink from "../Link/NavLink";

// type Props = {
// 	title: String,
// 	path: String
// };

const StyledLink = styled(NavLink)`
	align-self: flex-start;
	font-weight:bold;
`;

const LinkWrapper = styled.p`
	width: 100%;
	margin-top: 10px;
`;

const BackLink = ({ title, path }) => {
	const isPreview = useContext(PreviewContext);

	return (!isPreview ? <LinkWrapper>
		<StyledLink to={path}><FormNext />{title}</StyledLink>
	</LinkWrapper> : null);
};

export default BackLink;

